import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ChartConfiguration, ChartData, ChartOptions, ChartType } from "chart.js";
import { CustomColor, getRGBAString, COLORS } from "./utils";
import { Subject } from "rxjs";
@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
})
export class ChartComponent implements OnInit {
  @Input() resetFiltersSubject: Subject<void>;
  @Input() type: ChartType;
  @Input() chartHeight: number;
  dynamicWidth: number = 500;

  ngOnInit(): void {
    if (this.resetFiltersSubject != undefined) {
      this.resetFiltersSubject.subscribe(() => {
        this.resetColorsAndBorders();
        this.updateChartData();
      });
    }
  }

  private _colors: Array<CustomColor>;
  set colors(colors: Array<CustomColor>) {
    this._colors = colors;
  }

  get colors(): Array<CustomColor> {
    return this._colors;
  }

  private _data: number[];
  get data(): number[] {
    return this._data;
  }

  @Output() changeFilter: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input() set data(val: number[]) {
    if (val) {
      this._data = val;
      this.colors = COLORS.map((color) => new CustomColor(color[0], color[1], color[2], 1));
      this.setBorders(val.length);
      this.updateChartData();
    }
  }

  chartData: ChartData<"pie", number[], string | string[]>;

  private _borderColors: Array<string>;
  private _borderWidths: Array<number>;

  private setBorders(dataLength: number) {
    this._borderWidths = [];
    this._borderColors = [];
    for (let i = 0; i < dataLength; i++) {
      this._borderColors.push("#000000");
      this._borderWidths.push(0);
    }
  }

  @Input() labels: string[];

  private _options: ChartConfiguration["options"];
  get options() {
    return this._options;
  }

  @Input() set options(options: ChartOptions) {
    this._options = {
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          onClick: this.newLegendClickHandler,
        },
      },
    };
  }

  getColorsRGBAString(): Array<string> {
    return this.colors.map((color) => getRGBAString(color));
  }

  private updateChartData() {
    this.chartData = {
      labels: this.labels,
      datasets: [
        {
          data: this._data,
          backgroundColor: this.getColorsRGBAString(),
          borderColor: this._borderColors,
          borderWidth: this._borderWidths,
        },
      ],
    };
    this.updateWidth();
  }

  private newLegendClickHandler = (e, legendItem) => {
    this.highlightColorsAndBorders(legendItem.index);
    this.updateChartData();
    const chartFilterLabels = this._colors.reduce((accumulator, color, i) => {
      if (color.a === 1) {
        accumulator.push(this.labels[i]);
      }
      return accumulator;
    }, []);
    this.changeFilter.emit(chartFilterLabels.length === this.colors.length ? [] : chartFilterLabels);
  };

  private highlightColorsAndBorders(index: number): void {
    const allSelected: boolean = this._colors.every((color) => color.a === 1);
    this._colors.forEach((color, i) => {
      if (allSelected) {
        color.a = i == index ? 1 : 0.4;
        this._borderWidths[i] = i == index ? 1 : 0;
      } else {
        if (i == index) {
          this._borderWidths[index] = color.a < 1 ? 1 : 0;
          color.a = color.a < 1 ? 1 : 0.4;
        }
      }
    });
    if (this._colors.every((color) => color.a < 1)) {
      this._colors.forEach((color, i) => {
        color.a = 1;
        this._borderWidths[i] = 0;
      });
    }
    if (this._colors.every((color) => color.a == 1)) {
      this._colors.forEach((color, i) => {
        this._borderWidths[i] = 0;
      });
    }
  }

  private resetColorsAndBorders(): void {
    this._colors.forEach((color) => {
      color.a = 1;
    });
    for (let i = 0; i < this._borderWidths.length; i++) {
      this._borderWidths[i] = 0;
    }
  }

  private updateWidth(): void {
    if (this.labels == null) {
      return;
    }

    const baseWidth = 300;
    const labelWidth = 200;
    const labelsPerColumn = 7;
    const numberOfColumns = Math.ceil(this.labels.length / labelsPerColumn);

    const legendWidth = numberOfColumns * labelWidth;
    this.dynamicWidth = baseWidth + legendWidth;
  }
}
