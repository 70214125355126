import {
  AATConfig,
  CampaignDetailsConfig,
  CampaignGridActiveColumns,
  GridConfig,
  HeaderConfig,
  LeftSideBarConfig,
  MapConfig,
  Region,
  ResultsViewConfig,
  SearchGridActiveColumns,
} from "src/app/core/models/domain/RegionFeaturesConfig";
import {
  captions,
  Formats,
  CampaignBudgetDetailsFields,
  CampaignGridDropDownOptions,
  AvgImpression,
  CampaignActiveColumns,
  LongLat,
  GetOuterColumnsV3,
  GetInnerColumnsV3,
  GetAgencyOuterColumnsV3,
  ShareListingPageCaptions,
  filterCaptions,
  platoMapLibcaptions,
  supportsPrintMedia,
  enableSearchBySpotId,
  panelListOption,
  frameColorCoding,
  showRateType,
  frameLabelOnNavbar,
  loadDatesInFormatMenu,
  setFunctionsOfSpotIdBasedOnRegion,
  enableProhibition,
  mapViewControl,
  addFrameDialogComfirmation,
  isTradingTypeCPTByDefault,
  isGroupingConfigV3US,
  isGroupingConfigV3,
  showCycleType,
  showReferences,
  countryDropDown,
} from "./captions";
import { OptioningControlsConfiguration } from "src/app/core/models/domain/OptioningControlsConfiguration";
import * as cols from "./columns";
import { campaignConfig } from "./campaign-config";
import labels from "./labels";

export const config = {
  region: Region.IE,
  locale: "en_IE",
  labels: labels,
  mapBoxCountryId: "GB",
  mapBoxLongLat: [-1.8707006, 52.458702],
  mapDMALabelValue: captions.tv_region,
  mapDefaultZoom: 6,
  sourceSystems: ["SPACE", "Media Owner"],
  defaultSourceSystems: ["SPACE", "Media Owner"],
  defaultCountries: ["IE"],
  defaultDistanceUnit: "Metres",
  defaultDistanceValue: 500,
  dateFormat: "DD/MM/YYYY",
  impactBaseValue: 1000,
  enableClientDashboardControls: false,
  autoSelectEnabled: true,
  reportsEnabled: true,
  packsEnabled: true,
  digitalPacksEnabled: true,
  notificationsEnabled: true,
  excludeColumnsInSearchResult: [],
  optioningMode: {
    JCDECAUX: "auto",
    PRIMESIGHT: "auto",
    "OCEAN OUTDOOR": "auto",
    "CITY OUTDOOR MEDIA": "auto",
    "OUTDOOR PLUS": "auto",
    "LIMITED SPACE": "auto",
  },
  mediaOwnerServiceRequestsEnabled: true,
  containersEnabled: true,
  showContainerGrid: true,
  optioningControlsConfiguration: {
    prepareForOptioning: [
      {
        mediaOwnerName: "JCDECAUX",
      },
      {
        mediaOwnerName: "OCEAN OUTDOOR",
      },
      {
        mediaOwnerName: "PRIMESIGHT",
        faceType: "CLASSIC",
      },
      {
        mediaOwnerName: "CITY OUTDOOR MEDIA",
        faceType: "DIGITAL",
      },
      {
        mediaOwnerName: "OUTDOOR PLUS",
        faceType: "DIGITAL",
      },
      {
        mediaOwnerName: "LIMITED SPACE",
        faceType: "DIGITAL",
      },
    ],
    optioning: [
      {
        mediaOwnerName: "JCDECAUX",
      },
      {
        mediaOwnerName: "OCEAN OUTDOOR",
      },
      {
        mediaOwnerName: "PRIMESIGHT",
        faceType: "CLASSIC",
      },
      {
        mediaOwnerName: "CITY OUTDOOR MEDIA",
        faceType: "DIGITAL",
      },
      {
        mediaOwnerName: "OUTDOOR PLUS",
        faceType: "DIGITAL",
      },
      {
        mediaOwnerName: "LIMITED SPACE",
        faceType: "DIGITAL",
      },
    ],
    booking: [
      {
        mediaOwnerName: "JCDECAUX",
      },
      {
        mediaOwnerName: "OCEAN OUTDOOR",
      },
      {
        mediaOwnerName: "PRIMESIGHT",
        faceType: "CLASSIC",
      },
    ],
    cancellation: [
      {
        mediaOwnerName: "JCDECAUX",
      },
      {
        mediaOwnerName: "OCEAN OUTDOOR",
      },
    ],
  } as OptioningControlsConfiguration,
  ffcFormConfigurations: {
    showAllMediaOwners: false,
    defaultReachValue: "National",
    showTargetImpressions: false,
    showDefaultImpressions: true,
  },

  leftSideBarConfig: {
    format: true,
    frameType: true,
    formatGroup: true,
    formatFormat: true,
    environment: true,
    availabilityDate: true,
    daypart: true,
    availability: true,
    frameOwner: true,
    directions: false,
    frames: true,
    frameIds: true,
    frameOthers: true,
    frameExclude: true,
    audience: false,
    geography: true,
    country: true,
    tvRegion: true,
    town: true,
    conurbation: true,
    cbsa: false,
    city: false,
    geoPostalCode: true,
    geoPostalArea: true,
    marketArea: false,
    state: false,
    county: false,
    zipcode: false,
    poiAndProximity: true,
    poiPostalCode: true,
    poi: true,
    poiCategory: true,
    poiBrand: true,
    poiBusiness: true,
    poiDistance: true,
  } as LeftSideBarConfig,

  headerConfig: {
    freeTextSearch: true,
    inAppNotification: true,
  } as HeaderConfig,

  resultViewConfig: {
    kpiDetails: false,
  } as ResultsViewConfig,

  campaignDetailsConfig: {
    kpiSummary: true,
    containerGrid: true,
  } as CampaignDetailsConfig,

  gridConfig: {
    captions: captions,
    filterCaptions: filterCaptions,
    mapCaptions: platoMapLibcaptions,
    columns: cols.columns,
    searchGridColumns: cols.searchGridColumns,
    searchGridActiveColumnsPreference: SearchGridActiveColumns.UK,
    campaignGridActiveColumns: CampaignGridActiveColumns.UK,
    columnsWidth: cols.columnsWidth,
    columnsSortable: cols.columnsSortable,
    columnsDraggable: cols.columnsDraggable,
    mandatoryColumns: cols.mandatoryColumns,
    userSelectedColumns: cols.userSelectedColumns,
    pinnedRightColumns: cols.pinnedRightColumns,
    features: cols.features,
    featuresEnabled: cols.featuresEnabled,
  } as GridConfig,

  aatConfig: {
    isRouteVersionSelectionMandatory: true,
    isFileUploadEnabled: true,
    analyticalSystem: "Route",
    columns: cols.aatColumns,
    isAddAudienceEnabled: true,
    aatEntryUrl: "/audience-analysis-tool",
    isGeoTargetingEnabled: false,
  } as AATConfig,

  mapConfig: {
    defaultZoom: 5.5,
    shouldUpdatePrice: false,
    showPrice: false,
    listenPOIRenderingStatus: false,
    updateDMAAndCountyBoundaries: false,
  } as MapConfig,
  campaignConfig: campaignConfig,
  Formats,
  CampaignBudgetDetailsFields,
  CampaignGridDropDownOptions,
  AvgImpression,
  CampaignActiveColumns,
  LongLat,
  GetOuterColumnsV3,
  GetInnerColumnsV3,
  GetAgencyOuterColumnsV3,
  ShareListingPageCaptions,
  supportsPrintMedia,
  frameColorCoding,
  showRateType,
  enableSearchBySpotId,
  panelListOption,
  frameLabelOnNavbar,
  loadDatesInFormatMenu,
  setFunctionsOfSpotIdBasedOnRegion,
  enableProhibition,
  mapViewControl,
  addFrameDialogComfirmation,
  isTradingTypeCPTByDefault,
  isGroupingConfigV3US,
  isGroupingConfigV3,
  showCycleType,
  showReferences,
  countryDropDown,
};
