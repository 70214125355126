import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberWithComma",
})
export class NumberWithCommaPipe implements PipeTransform {
  transform(value: number): string {
    const num = Number(value);
    if (isNaN(num)) {
      return "NaN";
    }
    return num.toLocaleString();
  }
}
