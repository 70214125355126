<div *ngIf="data" [style.height.px]="chartHeight">
  <canvas
    baseChart
    [type]="type"
    [data]="chartData"
    [labels]="labels"
    [options]="options"
    [style.width.px]="dynamicWidth"
  >
  </canvas>
</div>
